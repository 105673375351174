import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="stage" className="limited_editions animated-stage">
          <div className="container">
            <div className="row text-center">
              <div className="stage-text col-md-10 col-md-offset-1">
                <h2>Limited Editions</h2>
                <p>
                  Süße Motive zieren seit 1995 die{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Produkte, die dadurch zum beliebten Sammlerobjekt geworden
                  sind – entdecke die Welt der{" "}
                  <span className="Kein_Umbruch">
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Limited Editions.
                  </span>{" "}
                </p>
              </div>
              <div className="desktop-view stage-image markenhistorie-stage col-xs-12">
                {" "}
                <img
                  src="/fileadmin/images/limited_editions/limited_editions.stage.bg.png"
                  width="980"
                  height="340"
                  alt
                />
                <div className="animated-items">
                  {" "}
                  <a
                    href="/limited-editions/#year-2014-hello"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-1"
                      src="/fileadmin/images/limited_editions/limited_editions.stage.hellokitty.2.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/limited-editions/#year-2019-peanuts"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-2"
                      src="/fileadmin/images/limited_editions/12110_bebe_Zartcreme_Vivi_Wallemaehne_Limited_Edition_150ml_EAN3574661444123.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/limited-editions/#content"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-3"
                      src="/fileadmin/images/limited_editions/limited_editions.stage.teaser.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/limited-editions/#year-2019-peanuts"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-4"
                      src="/fileadmin/images/limited_editions/12110_bebe_Zartcreme_Paula_Perlensanft_Limited_Edition_150ml_EAN3574661444123.png"
                      alt
                    />
                  </a>{" "}
                  <a
                    href="/limited-editions/#year-2019-peanuts"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-5"
                      src="/fileadmin/images/limited_editions/12110_bebe_Zartcreme_Bibi_Blubberblase_Limited_Edition_150ml_EAN3574661444123.png"
                      alt
                    />
                  </a>{" "}
                </div>
              </div>
              <div className="mobile-view stage-image markenhistorie-stage col-xs-12">
                {" "}
                <img
                  src="/fileadmin/images/limited_editions/limited_editions.stage.bg.mobile.png"
                  width="640"
                  height="584"
                  alt
                />
                <div className="animated-items">
                  {" "}
                  <a
                    href="/limited-editions/#content"
                    className="textlink-scroll"
                  >
                    <img
                      className="animated-item item-1 animate"
                      src="/fileadmin/images/limited_editions/limited_editions.stage.teaser.mobile.png"
                      alt
                      style={{
                        opacity: "1"
                      }}
                    />
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="bubbles-container">
              <div className="bubble1" />
              <div className="bubble2" />
              <div className="bubble3" />
              <div className="bubble4" />
            </div>
          </div>
        </div>
        <div className="scroll-bottom-arrow">
          <div className="arrow-animated" />
          <div className="arrow-hint" />
        </div>
        <div id="content" className="limited_editions">
          <div className="container">
            {" "}
            <a name="OnlineGame" />
            <div id="memory_game" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Limited Editions – das Spiel</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                <div id="memorygame" />
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <div className="cnggame">
                  <p>
                    Finde die{" "}
                    <strong>
                      bebe Zartpflege<sup>®</sup>
                    </strong>{" "}
                    Limited Editions, die zusammengehören, in dem du die Felder
                    paarweise aufdeckst.
                  </p>
                  <p>Viel Spaß!</p>
                </div>
              </div>
            </div>
            <a name="LimitedEditions" />
            <div
              className="row text-center"
              style={{
                "margin-bottom": "50px"
              }}
            >
              <div className="stage-text col-md-10 col-md-offset-1">
                <h1>
                  Entdecke hier die Welt der <br /> bebe Zartpflege® Limited
                  Editions.
                </h1>
              </div>
            </div>
            <div id="year-2019-peanuts" class="row">
			  <div class="content-heading col-xs-12 col-sm-6  pull-right">
			   <h2>bebe Zartpflege® Freundinnen Edition (2019)</h2>
			   </div>
			   <div class="content-image col-xs-12 col-sm-6"> 
			   <img alt="bebe" src="/fileadmin/images/limited_editions/peanuts-group.png" width="437" height="345" /> 
			   </div>
			   <div class="content-text col-xs-12 col-sm-6">
			   <p>Tauche ein in die Welt unserer zauberhaft süßen bebe Zartpflege Freundinnen und sicher dir Deine Lieblings-Freundin als Limited Edition!</p>
			   </div>
			</div>
            <div id="year-2018-peanuts" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Peanuts (2018)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions_content.2019.png"
                  width="437"
                  height="345"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Die Peanuts sind los! Der verträumte Beagle Snoopy und seine
                  Freunde zieren ab jetzt die bebe Zartcreme® Dosen und erfreuen
                  mit ihren süßen Motiven Jung und Alt.
                </p>
              </div>
            </div>
            <div id="year-2017-hello" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Hello Kitty (2017)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions_content.2017.png"
                  width="437"
                  height="345"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Freunde braucht man fürs Leben – mit ihnen gehen wir durch
                  dick und dünn: In diesem Jahr widmet sich die Kultkatze dem
                  Thema „friends 4ever“. Die bebe Zartcreme zeigt sich in drei
                  süßen Designs mit Hello Kitty und ihren kleinen Freunden.
                </p>
              </div>
            </div>
            <div id="year-2016-hello" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Hello Kitty (2016)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions_content.2016.png"
                  width="437"
                  height="345"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Frühling, Herbst und Winter – in 2016 begleitet Euch Hello
                  Kitty durch drei der vier Jahreszeiten mit vielen
                  saisontypischen tierischen Freunden.
                </p>
              </div>
            </div>
            <div id="year-2014-hello" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Hello Kitty (2014)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.hellokitty.2014.png"
                  width="430"
                  height="370"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Aufgrund ihrer großen Beliebtheit ziert eine alte Bekannte
                  erneut die <strong>bebe</strong>® Zartcreme Dosen: die
                  niedliche Hello Kitty, die bei Klein &amp; Groß die Herzen
                  höher schlagen lässt.&nbsp;
                </p>
                <p>
                  <span
                    style={{
                      "line-height": "1.42857143"
                    }}
                  >
                    Erhältlich ist die Hello Kitty Limited Edition in 3 süßen
                    Motiven: Katzengesicht, Blumengießen und Vogelfreunde.
                  </span>
                </p>
              </div>
            </div>
            <div id="year-2014-maja" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Biene Maja Edition (2014)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.bienemaja.2014.png"
                  width="448"
                  height="318"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  <strong>bebe Zartpflege®</strong> bekommt Zuwachs: Die neuen{" "}
                  <strong>bebe Zartpflege®</strong> Milde Reinigungstücher.
                  Speziell für zarte Kinderhaut entwickelt, sind die
                  Reingungstücher der praktische Helfer für zu Hause und
                  unterwegs.&nbsp;
                </p>
                <p>
                  <span
                    style={{
                      "line-height": "1.42857143"
                    }}
                  >
                    Im Mai 2014 war die <strong>bebe</strong>® Zartcreme Limited
                    Edition mit Biene Maja im Handel erhältlich. Gemeinsam mit
                    ihren Freunden Willi und Grashüpfer Flip ziert die kleine
                    Entdeckerin Biene Maja die Dosen der bebe® Zartcreme in
                    allen drei Grössen (30ml/75ml/250ml).
                  </span>
                </p>
              </div>
            </div>
            <div id="year-2013-sommer" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Sommer (2013)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2013.sommer.png"
                  width="437"
                  height="342"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Ein rosa Sommer für Groß und Klein, mit der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  im sommerlichen Design. Die unterschiedlichen Sommermotive
                  sind bei den Cremeliebhabern ganz besonders beliebt.{" "}
                </p>
              </div>
            </div>
            <div id="year-2012-hello" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Hello Kitty (2012)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2012.hellokitty.png"
                  width="419"
                  height="441"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Verspielt, niedlich und ein bisschen schüchtern – Hello Kitty,
                  das weiße Kätzchen mit der Schleife im Haar feiert Geburtstag
                  und ist mit drei Motiven auf der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  zu sehen.{" "}
                </p>
              </div>
            </div>
            <div id="year-2012" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Design Edition (2012)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2012.designedition.png"
                  width="479"
                  height="350"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Feen, Tupfen und Herzchen – die drei Gewinner-Motive des
                  großen Design-Wettbewerbs anlässlich des 50. Geburtstags der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  zieren die Dosen der Limited Edition.{" "}
                </p>
              </div>
            </div>
            <div id="year-2011" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>50 Jahre bebe Zartpflege® (2011)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2011.50jahren.png"
                  width="493"
                  height="360"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Happy Birthday,{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  ! 50 Kerzen brennen 2011 auf der Geburtstagstorte und
                  natürlich gibt es zu diesem Anlass eine limitierte
                  Geburtstags-Edition der Zartcreme.{" "}
                </p>
              </div>
            </div>
            <div id="year-2010" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Winnie Puuh (2010)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2010.pooh.png"
                  width="430"
                  height="377"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Das große Abenteuer von Winnie Puuh: Der honighungrige Bär und
                  seine Freunde zieren die{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;Zartcreme und lassen mit bärenstarken Motiven die Herzen
                  höher schlagen.{" "}
                </p>
              </div>
            </div>
            <div id="year-2009" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Edition Micky Maus (2009)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2009.mickey.hires.png"
                  width="396"
                  height="327"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  überbringt liebevolle Wünsche für jeden Anlass – mit Walt
                  Disneys Kultfigur Micky Maus auf allen&nbsp;
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Produkten.
                  <strong>
                    <br />
                  </strong>
                </p>
              </div>
            </div>
            <div id="year-2008" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Edition Janosch (2008)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2008.edition-janosch.png"
                  width="466"
                  height="247"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  Oh, wie schön ist zarte Pflege: die Tigerente feiert
                  Geburtstag und ihre Freunde feiern mit. Tiger, Bär und Ente
                  vergnügen sich mit fünf frühlingshaften Motiven auf der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  .{" "}
                </p>
              </div>
            </div>
            <div id="year-2007" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>König Macius (2007)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2007.koenig-macius.png"
                  width="506"
                  height="265"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Königliche Pflege für zarte Haut: der kleine König Macius
                  thront pünktlich zum Filmstart in verschiedenen Motiven auf
                  der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  .
                </p>
              </div>
            </div>
            <div id="year-2006" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Snoopy &amp; the Peanuts (2006)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2006.snoopy.png"
                  width="483"
                  height="330"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  {" "}
                  Der berühmte schwarz-weiße Hund und seine Freunde aus den
                  bekannten Comics dürfen in der Sammlung der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Limited Editions natürlich nicht fehlen.
                </p>
              </div>
            </div>
            <div id="year-2004" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Hund und Elefant (2004)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2004.hund-elefant.png"
                  width="516"
                  height="391"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Hund und Elefant zieren die{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Limited Edition Produkte und erfreuen die Fans der Cremedose
                  mit süßen Motiven.
                </p>
              </div>
            </div>
            <div id="year-2003" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Benni der Bär (2003)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2003.benni-baer.png"
                  width="487"
                  height="358"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  {" "}
                  Benni der Bär bereichert die{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Limited Editions um ein Herzensmotiv: Er überbringt herzliche
                  Glückwünsche auf den Dosen der Zartcreme.{" "}
                </p>
              </div>
            </div>
            <div id="year-2002" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Best Friends (2002)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2002.best-friends.1.png"
                  width="342"
                  height="342"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Freunde für immer – der kleine Eisbär Lars und seine besten
                  Freunde, der Papageientaucher Juri und natürlich die kleine
                  Schneehäsin Lena, erleben gemeinsam Abenteuer.
                </p>
              </div>
            </div>
            <div id="year-2000" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Edition - Bella, Tibs &amp; Taps (2000)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2000.bella-tibs-taps.hires.png"
                  width="458"
                  height="392"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Alle Fans der Zartcreme freuen sich über Bella, Tibs &amp;
                  Taps, die bereits zum dritten Mal ein Gastspiel auf der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;Zartcreme Limited Edition geben.
                </p>
              </div>
            </div>
            <div id="year-2000-2" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Edition "Tierkinder" (2000)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.2000.affe-pinguin-esel.png"
                  width="487"
                  height="389"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Auch Tierkinder lieben die{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;Zartcreme: Pinguin, Esel, Affe &amp; Co. tummeln sich
                  nach Lust und Laune auf der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>{" "}
                  Limited Edition.
                </p>
              </div>
            </div>
            <div id="year-1999" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Edition - Bella, Tibs &amp; Taps (1999)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.1999.bella-tibs-taps.hires.png"
                  width="474"
                  height="359"
                />{" "}
              </div>
              <div className='content-text col-xs-12" col-sm-6'>
                <p>
                  {" "}
                  Katzenmama Bella und ihre zwei süßen Katzenkinder Tibs und
                  Taps spielen, tollen und schmusen auf der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;Zartcreme Limited Edition.
                </p>
              </div>
            </div>
            <div id="year-1998" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Edition - Bella, Tibs &amp; Taps (1998)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.1998.bella-tibs-taps.png"
                  width="368"
                  height="367"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Bella, die liebevolle Katzenmama, feiert mit ihren Kleinen,
                  Tibs und Taps, Premiere auf der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;Zartcreme Limited Edition.
                </p>
              </div>
            </div>
            <div id="year-1997-2" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Edition - Kater Jacob (1997)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.1997.kater-jakob.png"
                  width="368"
                  height="367"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Willkommen in der Welt von Kater Jacob! Der lebensfrohe
                  Cartoon-Kater bereichert die Welt der{" "}
                  <strong>
                    bebe<sup>®</sup>
                  </strong>
                  &nbsp;Zartcreme Limited Editions mit seinem freundlichen
                  Erscheinungsbild.
                </p>
              </div>
            </div>
            <div id="year-1996" className="row">
              <div className="content-heading col-xs-12 col-sm-6">
                <h2>Edition - Clown &amp; Löwe (1996)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6 pull-right">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.1996.clow-loewe.png"
                  width="365"
                  height="368"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  Manege frei für Zirkus Zartpflege: Clown, Löwe &amp; Co.
                  schmücken die runden Dosen und machen damit nicht nur den
                  Cremeliebhabern eine Freude.{" "}
                </p>
              </div>
            </div>
            <div id="year-1995" className="row">
              <div className="content-heading col-xs-12 col-sm-6 pull-right">
                <h2>Edition Boot &amp; Ballon (1995)</h2>
              </div>
              <div className="content-image col-xs-12 col-sm-6">
                {" "}
                <img
                  alt="bebe"
                  src="/fileadmin/images/limited_editions/limited_editions.content.1995.boot-ballon.hires.png"
                  width="474"
                  height="364"
                />{" "}
              </div>
              <div className="content-text col-xs-12 col-sm-6">
                <p>
                  {" "}
                  Weitere tolle Motive, "Bötchen" und "bunte Luftballons" aus
                  dem großen Design-Wettbewerb der{" "}
                  <strong>
                    bebe Zartpflege<sup>®</sup>
                  </strong>
                  .{" "}
                </p>
              </div>
            </div>
			
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.memorygame77ff.js?1510656653"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
